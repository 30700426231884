import React from "react"

import { StyledHeadingBar } from "../../styles/headings"
import {
  RelatedArticleList,
  RelatedArticleWrapper,
} from "./RelatedArticleElements"
import StoryCard from "../RecentStory/StoryCard"

const RelatedArticle = ({
  articleSlug,
  categoryTitle,
  subCategoryTitle,
  relatedArticleNode,
  categorySlug,
  subCategorySlug,
}) => {
  const filteredArticles = relatedArticleNode.filter(
    node => node.slug !== articleSlug
  )

  const articleLimit = filteredArticles.slice(0, 12)

  return (
    <RelatedArticleWrapper>
      {relatedArticleNode.length > 1 && (
        <>
          <StyledHeadingBar>
            More from <span>{subCategoryTitle ?? categoryTitle}</span>
          </StyledHeadingBar>

          <RelatedArticleList>
            {articleLimit.map(item => {
              return (
                <StoryCard
                  key={item.id}
                  story={item}
                  fullSlug={`/${categorySlug}/${subCategorySlug}/${item.slug}/`}
                />
              )
            })}
          </RelatedArticleList>
        </>
      )}
    </RelatedArticleWrapper>
  )
}

export default RelatedArticle
