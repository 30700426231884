import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  StoryCardWrapper,
  StoryCardContent,
  StoryCardLink,
} from "./StoriesElements"

const StoryCard = ({ story, fullSlug }) => {
  const { title, thumbnail, validDate, date } = story
  const imageData = getImage(thumbnail)

  return (
    <StoryCardWrapper>
      <StoryCardLink to={fullSlug}>
        <GatsbyImage className="articleImage" image={imageData} alt={title} />

        <StoryCardContent>
          <h3>{title}</h3>
          <footer>
            <time dateTime={validDate}>{date}</time>
          </footer>
        </StoryCardContent>
      </StoryCardLink>
    </StoryCardWrapper>
  )
}

export default StoryCard
