import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { FaMapMarkerAlt } from "react-icons/fa"
import config from "../utils/siteConfig"

import Seo from "../components/seo"
import Breadcrumb from "../components/Breadcrumb"
import { ArticleMain } from "../styles/Layout"
import ShareIcons from "../utils/ShareIcons"
import {
  ArticleWrapper,
  ArticleTitle,
  SubTitle,
  NewsLoc,
  PostMeta,
  StyledPostContent,
} from "../components/Article/ArticleElements"
import VideoPlayer from "../components/VideoPlayer"
import AuthorBox from "../components/Article/AuthorBox"
import RelatedArticle from "../components/RelatedArticle"
import Newsletter from "../components/Newsletter"
import EndShareButtons from "../EndShareButtons"
import AdLeaderboard from "../components/Ads/AdLeaderboard"
import MiniSidebar from "../components/MiniSidebar"

const Article = ({
  pageContext,
  data: { site, article, adLeaderboard, review, brand, bizExec },
  location,
}) => {
  const relatedArticleNode = article.subCategory.article
  const hasSubCategory = article.subCategory && `/${article.subCategory.slug}`
  const adData = adLeaderboard
  const reviewData = review
  const brandData = brand
  const execData = bizExec

  const image = getImage(article.featuredImage)
  const authorImage = getImage(article.author.photo)

  // Image source for Open Graph image
  const featuredImgSrc = getSrc(article?.featuredImage)
  const authorImgSrc = getSrc(article.author.photo)

  const jsonLd = {
    "@context": "https://schema.org/",
    "@graph": [
      {
        "@type": `Article`,
        mainEntityOfPage: {
          "@type": `WebPage`,
          "@id": `${config.siteUrl}/`,
        },
        // keywords:
        headline: article.title,
        description: article.seoDesc,
        url: location.href,
        datePublished: article.date,
        dateModified: article.updatedAt,
        image: featuredImgSrc
          ? {
              "@type": `ImageObject`,
              url: featuredImgSrc,
              width: config.shareImageWidth,
              height: config.shareImageHeight,
            }
          : undefined,
        publisher: {
          "@type": `Organization`,
          name: config.siteName,
          logo: {
            "@type": `ImageObject`,
            "@id": `${config.siteUrl}/#logo/`,
            inLanguage: "en-US",
            url: `${config.siteUrl}/logo.png/`,
            width: 60,
            height: 60,
            caption: config.siteName,
          },
          image: {
            "@id": "https://ceiimages.com/#logo",
          },
        },
        articleSection: article.subCategory.title ?? article.category.title,
        inLanguage: "en-US",
        author: {
          "@type": `Person`,
          // "@id": "",
          name: article.author.name,
          image: authorImgSrc
            ? {
                "@type": `ImageObject`,
                url: authorImgSrc,
              }
            : undefined,
          // TODO: Add sameAs array
        },
      },
    ],
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
    },

    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_2]: (_node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (_node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (_node, children) => <h5>{children}</h5>,
      // Handling images
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const articleImage = getImage(node.data.target)

        return (
          <GatsbyImage
            as="figure"
            // image={articleImage}
            // @ts-ignore
            image={articleImage}
            alt={node.data.target.description}
            style={{
              width: "720",
            }}
          />
        )
      },

      [INLINES.ENTRY_HYPERLINK]: ({
        data: {
          target: { slug, title },
        },
      }) => <Link to={slug}>{title}</Link>,

      // Handling responsive videos
      [INLINES.HYPERLINK]: node => {
        if (
          (node.data.uri.indexOf(`youtube.com`) ||
            node.data.uri.indexOf(`youtu.be`) ||
            node.data.uri.indexOf(`vimeo.com`) ||
            node.data.uri.indexOf(`wistia.com`) ||
            node.data.uri.indexOf(`vidme.com`) ||
            node.data.uri.indexOf(`dailymotion.com`) ||
            node.data.uri.indexOf(`twitch.com`) ||
            node.data.uri.indexOf(`vidyard.com`) ||
            node.data.uri.indexOf(`kaltura.com`) ||
            node.data.uri.indexOf(`streamable.com`) ||
            node.data.uri.indexOf(`soundcloud.com`) ||
            node.data.uri.indexOf(`facebook.com`)) !== -1
        ) {
          return <VideoPlayer url={node.data.uri} />
        }

        if (node.data.uri.includes(`ceiimages.com`)) {
          return (
            <Link
              to={node.data.uri.replace(/(http|https):\/\/ceiimages.com/, ``)}
              aria-label={node.data.title}
            >
              {node.content[0].value}
            </Link>
          )
        } else {
          return (
            <a
              href={node.data.uri.replace(/http:/, `https:`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.content[0].value}
            </a>
          )
        }
      },
    },
  }

  return (
    <>
      <Seo
        // location={location}
        title={article.title}
        description={article.seoDesc}
        image={featuredImgSrc}
        imageAlt={article?.featuredImage?.description || article?.title}
        article={true}
      >
        <script type="application/ld+json">
          {JSON.stringify(jsonLd, undefined, 4)}
        </script>
      </Seo>
      <ArticleMain>
        <Breadcrumb
          className={null}
          categoryTitle={article.category?.title}
          categoryLink={article.category?.slug}
          subCategoryTitle={article.subCategory?.title}
          subCategoryLink={hasSubCategory}
        />
        <ArticleWrapper id="main-content">
          <ArticleTitle>
            <h1>{article.title}</h1>
          </ArticleTitle>

          <PostMeta>
            <div className="authorInfo">
              <GatsbyImage
                className="authorImage"
                as="figure"
                // @ts-ignore
                image={authorImage}
                alt={article.author?.name}
              />
              <p>
                <time dateTime={new Date(article.date).toISOString()}>
                  {article.date}
                </time>
                <span>{article.author?.name}</span>
              </p>
            </div>

            <ShareIcons
              url={`${site.siteMetadata.siteUrl}/${article.category.slug}${hasSubCategory}/${article.slug}/`}
            />
          </PostMeta>

          {article.subTitle && <SubTitle>{article.subTitle}</SubTitle>}

          <MiniSidebar
            reviewData={reviewData}
            brandData={brandData}
            execData={execData}
          />

          <GatsbyImage
            as="figure"
            // @ts-ignore
            image={image}
            alt={article?.featuredImage?.description}
            style={{
              gridColumn: "full-start/full-end",
              height: "min(68vw, 72rem)",
            }}
          />

          <StyledPostContent>
            {article?.location && (
              <NewsLoc>
                <FaMapMarkerAlt /> {article?.location}
              </NewsLoc>
            )}

            {renderRichText(article.content, options)}

            <EndShareButtons
              site={site}
              article={article}
              hasSubCategory={hasSubCategory}
            />

            <Newsletter
              formHeading="our Newsletter"
              formWidth="fixed-width"
              // className="fixed-width"
            />

            <AuthorBox {...article.author} />

            <RelatedArticle
              categoryTitle={pageContext.category}
              subCategoryTitle={pageContext.subCategory}
              articleSlug={pageContext.slug}
              relatedArticleNode={relatedArticleNode}
              categorySlug={article.category.slug}
              subCategorySlug={article.subCategory.slug}
            />
          </StyledPostContent>
        </ArticleWrapper>
        <AdLeaderboard {...adData} homepage />
        <Newsletter formWidth="true" />
      </ArticleMain>
    </>
  )
}

export default Article

export const articleQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    article: contentfulArticle(slug: { eq: $slug }) {
      slug
      seoDesc: seoDescription
      title
      subTitle
      location
      date: publishDate(formatString: "MMM DD, YYYY")
      updatedAt
      subCategory {
        title
        slug
        article {
          id: contentful_id
          title
          slug
          date: publishDate(formatString: "MM.DD.YYYY")
          category {
            title
          }
          subCategory {
            title
          }
          thumbnail: featuredImage {
            description
            gatsbyImageData(width: 80, height: 80, quality: 40)
          }
        }
      }
      category {
        title
        slug
      }
      featuredImage {
        description
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 720, layout: CONSTRAINED, quality: 80)
            description
          }
        }
      }
      tags
      author {
        name
        bio {
          bio
        }
        photo {
          gatsbyImageData(width: 64, height: 64, layout: CONSTRAINED)
        }
      }
    }

    adLeaderboard: contentfulAds(
      adType: { eq: "Leaderboard" }
      adActive: { eq: true }
      homepageAd: { eq: true }
    ) {
      title
      adUrl
      adType
      adGraphic {
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
      }
    }

    review: allContentfulReview(
      sort: { fields: publishDate, order: DESC }
      limit: 1
      skip: 1
      filter: { slug: { nin: [$slug, null] } }
    ) {
      nodes {
        title
        slug
        pubDate: publishDate(formatString: "MMM DD, YYYY")
        featuredImage {
          description
          gatsbyImageData(width: 320, height: 160, layout: CONSTRAINED)
        }
        category {
          slug
        }
        subCategory {
          slug
        }
      }
    }

    brand: allContentfulArticle(
      limit: 1
      skip: 1
      sort: { fields: publishDate, order: DESC }
      filter: {
        slug: { nin: [$slug, null] }
        subCategory: { title: { eq: "Brands of the world" } }
      }
    ) {
      nodes {
        title
        slug
        pubDate: publishDate(formatString: "MMM DD, YYYY")
        featuredImage {
          description
          gatsbyImageData(width: 320, height: 160, layout: CONSTRAINED)
        }
        category {
          slug
        }
        subCategory {
          slug
        }
      }
    }

    bizExec: allContentfulArticle(
      limit: 1
      skip: 1
      sort: { fields: publishDate, order: DESC }
      filter: {
        slug: { nin: [$slug, null] }
        subCategory: { title: { eq: "Business Executives" } }
      }
    ) {
      nodes {
        title
        slug
        pubDate: publishDate(formatString: "MMM DD, YYYY")
        featuredImage {
          description
          gatsbyImageData(width: 320, height: 160, layout: CONSTRAINED)
        }
        category {
          slug
        }
        subCategory {
          slug
        }
      }
    }
  }
`
