import styled from "@emotion/styled"

export const RelatedArticleWrapper = styled.section`
  grid-column: 1 / -1;
  margin-block: 4rem 2rem;
  margin-inline: 1rem;
  padding: 0 calc((100% - 96rem) / 2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;

  h3 {
    padding-bottom: 0;
    padding-top: 4rem;
  }
`

export const RelatedArticleList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  gap: 4rem;

  & > * {
    flex: 1 1 25%;
    /* margin: 0 4rem 4rem 0; */
  }

  a {
    &:link,
    &:visited {
      color: initial;
      text-decoration: initial;
    }

    &:hover,
    &:active {
      opacity: 0.75;
    }
  }
`
